import React from 'react'
import PropTypes from 'prop-types'
import { Spacing50, Spacing100 } from 'styles/sharedStyle'
import AccordionItem from './AccordionItem'
import { Heading, List, Wrapper } from './Accordion.style'
import { fixAudiences } from '../../utils/audiences'

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    this.id =
      props.name ||
      `accordion_${Math.random()
        .toString(36)
        .substring(7)}`

    this.state = {
      current: null,
    }

    this.toggle = (index) => {
      this.setState(({ current }) => ({
        current: current === index ? null : index,
      }))
    }
  }

  render() {
    const {
      audiences,
      children,
      heading,
      items,
      spacing,
      villageSlug,
    } = this.props
    const { current } = this.state
    const hasAudiences = !!audiences

    return (
      <>
        <Wrapper hasAudience={hasAudiences} className={fixAudiences(audiences)}>
          {spacing && <Spacing100 />}
          {heading && (
            <>
              <Heading>{heading}</Heading>
              {spacing && <Spacing50 />}
            </>
          )}
          <List>
            {items &&
              items.map((item, i) => (
                <AccordionItem
                  active={current === i}
                  toggle={this.toggle}
                  index={i}
                  name={this.id}
                  {...item}
                  key={`accordion-item__${i.toString()}`}
                  villageSlug={villageSlug}>
                  {!!children &&
                    (children.length === items.length ||
                      (typeof children === 'object' && items.length === 1)) &&
                    children}
                </AccordionItem>
              ))}
          </List>
        </Wrapper>
        {spacing && <Spacing50 />}
      </>
    )
  }
}

Accordion.propTypes = {
  audiences: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  name: PropTypes.string,
  spacing: PropTypes.bool,
  villageSlug: PropTypes.string,
}

Accordion.defaultProps = {
  audiences: '',
  children: null,
  heading: '',
  items: [],
  name: '',
  spacing: true,
  villageSlug: '',
}

export default Accordion
