import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Spacing100 } from 'styles/sharedStyle'
import absoluteUrl from 'utils/absoluteUrl'
import formOptionFormat from 'utils/formOptionFormat'
import { FormCheckbox, useForm, Recaptcha } from 'components/Forms'
import Accordion from '../Accordion'
import getFields from './getFields'
import getOpening from './getOpening'
import getServices from './getServices'
import getHourOptions from './getHourOptions'

import {
  AccSection,
  Actions,
  Center,
  CheckboxCell,
  CheckboxGroup,
  CheckboxGroupLabel,
  CheckboxRow,
  Consent,
  FormColumn,
  FormGrid,
  Headline,
  Section,
  SubmitBtn,
  Wrapper,
} from './GroupBooking.style'

const GroupBooking = ({
  additionalServicesTitle,
  ctaLabel,
  groupsAndEventsTitle,
  groupTypeOptions,
  locale,
  locOrigin,
  primaryContactDetailsTitle,
  recaptchaKey,
  secondaryContactDetailsTitle,
  serviceSelectionLabel,
  url,
  allVillagesInfo,
  selectVillageOptions,
  villageSlug,
  ...props
}) => {
  const village =
    selectVillageOptions && selectVillageOptions.length
      ? selectVillageOptions[0].value
      : null
  const allOpening = getOpening(allVillagesInfo)
  const allServices = getServices(allVillagesInfo)

  const [hours, setHours] = useState(
    allOpening[village]
      ? allOpening[village].hours
      : new Array(7).fill({ opening: '00:00', closing: '00:00' })
  )
  const [startDate, setStartDate] = useState(
    allOpening[village] ? allOpening[village].startDate : new Date()
  )
  const [exceptions, setExceptions] = useState(
    allOpening[village] ? allOpening[village].exceptions : []
  )
  const [daysOfWeek, setDaysOfWeek] = useState(
    allOpening[village]
      ? allOpening[village].daysOfWeek
      : new Array(7).fill(false)
  )
  const [openingTimes, setOpeningTimes] = useState(hours[startDate.getDay()])
  const [verified, setVerified] = useState(false)
  const [modeOfTransportOptions, setModeOfTransportOptions] = useState(
    allServices[village] ? allServices[village].modeOfTransportOptions : []
  )
  const [serviceSelectionOptions, setServiceSelectionOptions] = useState(
    allServices[village] ? allServices[village].serviceSelectionOptions : []
  )

  const {
    consentFields,
    fields, // Concatenated object of all fields
    groupFields,
    primaryContactFields,
    secondaryContactFields,
    servicesChecks,
    servicesOther,
  } = getFields({
    groupTypeOptions,
    modeOfTransportOptions,
    startDate,
    selectVillageOptions,
    serviceSelectionOptions,
    ...props,
  })

  const availableServicesChecks = {}
  Object.keys(servicesChecks).forEach((key) => {
    if (servicesChecks[key].copy) {
      availableServicesChecks[key] = servicesChecks[key]
    }
  })

  const {
    form,
    handleChange,
    handleChangeByField,
    handleCheckbox,
    validateForm,
  } = useForm(fields)

  const handleModeOfTransport = (e) => {
    if (!e.target.checked && form.serviceModeOfTransport) {
      form.serviceModeOfTransport.value = ''
    }
    handleCheckbox(e)
  }

  const handleSecondaryToggle = (open) => {
    if (!open) {
      secondaryContactFields.map((obj) => {
        return Object.keys(obj).map((key) => {
          if (form[key] && form[key].value) {
            handleChangeByField('', key)
          }
          return false
        })
      })
    }
  }

  const cleanTimeFields = () => {
    if (form.timeOfArrival) {
      form.timeOfArrival.value = ''
    }
    if (form.timeOfDeparture) {
      form.timeOfDeparture.value = ''
    }
  }

  const updateOpeningHours = (selectedVillage) => {
    if (selectedVillage in allOpening) {
      const {
        daysOfWeek: newDaysOfWeek,
        hours: newHours,
        startDate: newStartDate,
        exceptions: newExceptions,
      } = allOpening[selectedVillage]
      setDaysOfWeek(newDaysOfWeek)
      setHours(newHours)
      setStartDate(newStartDate)
      setExceptions(newExceptions)
      cleanTimeFields()
      if (form.expectedArrival && form.expectedArrival.value) {
        setOpeningTimes(newHours[form.expectedArrival.value.getDay()])
      }
    }
  }

  const cleanServicesChecks = () => {
    Object.keys(servicesChecks).forEach((key) => {
      if (form[key]) {
        form[key].value = ''
      }
    })
    if (form.serviceModeOfTransport) {
      form.serviceModeOfTransport.value = ''
    }
  }

  const updateVillageServices = (selectedVillage) => {
    if (selectedVillage in allServices) {
      setModeOfTransportOptions(
        allServices[selectedVillage].modeOfTransportOptions || []
      )
      setServiceSelectionOptions(
        allServices[selectedVillage].serviceSelectionOptions || []
      )
    } else {
      setModeOfTransportOptions([])
      setServiceSelectionOptions([])
    }
    cleanServicesChecks()
  }

  const handleVillageChange = (event, name = event.target.name) => {
    updateOpeningHours(event.target.value)
    updateVillageServices(event.target.value)
    handleChange(event, name)
  }

  const handleDateChange = (newDate, name) => {
    cleanTimeFields()
    handleChangeByField(newDate, name)
    setOpeningTimes(hours[newDate.getDay()])
  }

  const submitFormRef = React.createRef()
  const recaptchaRef = React.createRef()

  const submitFields = {
    encoding: 'UTF-8',
    retURL: absoluteUrl(url),
    oid: '00Db0000000KhKW',
    recordType: '012b0000000bAwJ',
  }

  Object.keys(fields)
    .filter((key) => !!fields[key].queryName)
    .map((key) => {
      const { queryName } = fields[key]

      if (key in servicesChecks) {
        if (!(key in availableServicesChecks)) {
          return form[key].value
        }
        submitFields[queryName] = form[key].value ? 1 : 0
      } else {
        submitFields[queryName] = form[key].value
      }

      if (key === 'timeOfArrival' || key === 'expectedArrival') {
        const arrivalDateTime = new Date(
          `${form.expectedArrival.value.toDateString()} ${
            form.timeOfArrival.value
          }`
        )
        submitFields['00Nb0000007pmDB'] = arrivalDateTime
      }

      if (key === 'timeOfDeparture' || key === 'expectedArrival') {
        const departureDateTime = new Date(
          `${form.expectedArrival.value.toDateString()} ${
            form.timeOfDeparture.value
          }`
        )
        submitFields['00Nb0000007pmDL'] = departureDateTime
      }

      return form[key].value
    })

  const submitForm = () => {
    if (validateForm() && submitFormRef.current && verified) {
      submitFormRef.current.submit()
    }
  }

  const handleSubmit = () => {
    if (verified) {
      submitForm()
    } else {
      recaptchaRef.current.execute()
    }
  }

  const verifyRecaptcha = (response) => {
    if (response) {
      setVerified(true)
    } else {
      setVerified(false)
    }
  }

  useEffect(() => {
    if (verified) {
      submitForm()
    }
  }, [verified])

  const getChangeHandler = (key) => {
    if (key === 'expectedArrival') {
      const blockedDates = exceptions
        .filter((exception) => exception.closedFlag)
        .map((exception) => {
          const date = exception.date.split('-')
          return new Date(date[2], parseInt(date[1], 10) - 1, date[0])
        })
      return { handleDateChange, daysOfWeek, blockedDates }
    }
    if (key === 'village') {
      return { handleChange: handleVillageChange }
    }
    return { handleChange }
  }

  const formatDate = (date) => {
    const day = `0${date.getDate()}`.slice(-2)
    const month = `0${date.getMonth() + 1}`.slice(-2)
    return `${day}-${month}-${date.getFullYear()}`
  }

  const getTimeFieldOptions = (key) => {
    const exception =
      form.expectedArrival && form.expectedArrival.value
        ? exceptions.find(
            (ex) =>
              !ex.closedFlag &&
              ex.date === formatDate(form.expectedArrival.value)
          )
        : null
    const opening = exception ? exception.openingTime : openingTimes.opening
    const closing = exception ? exception.closingTime : openingTimes.closing
    if (key === 'timeOfArrival') {
      return {
        options: formOptionFormat(
          getHourOptions(
            opening,
            (form.timeOfDeparture && form.timeOfDeparture.value) || closing
          )
        ),
      }
    }
    return {
      options: formOptionFormat(
        getHourOptions(
          (form.timeOfArrival && form.timeOfArrival.value) || opening,
          closing
        )
      ),
    }
  }

  return (
    <Wrapper>
      <Center>
        <Section>
          <Spacing100 />
          <Headline>{groupsAndEventsTitle}</Headline>
          <FormGrid>
            {groupFields.map((half, i) => (
              <FormColumn
                section="groupFields"
                index={i}
                key={`half${i.toString()}`}>
                {Object.keys(half).map((key, j) => {
                  const {
                    Component,
                    value: initialValue,
                    options,
                    ...field
                  } = fields[key]
                  const value = form[key] ? form[key].value : ''
                  const { hasError } = form[key]
                  return (
                    <Component
                      {...field}
                      hasError={hasError}
                      value={value}
                      {...getChangeHandler(key)}
                      {...(() => {
                        let obj = {}
                        if (
                          key === 'timeOfArrival' ||
                          key === 'timeOfDeparture'
                        ) {
                          obj = getTimeFieldOptions(key)
                        } else if (options) {
                          obj = {
                            options,
                          }
                        }
                        return obj
                      })()}
                      key={`${field.name}${j.toString()}`}
                    />
                  )
                })}
              </FormColumn>
            ))}
          </FormGrid>
        </Section>
        {form.groupType && form.groupType.value === groupTypeOptions[0] && (
          <>
            <Spacing100 />
            <Section>
              <Spacing100 />
              <Headline>{additionalServicesTitle}</Headline>
              <CheckboxGroupLabel>{serviceSelectionLabel}</CheckboxGroupLabel>
              <CheckboxGroup>
                {[Object.keys(availableServicesChecks)].map((arr, i) => (
                  <CheckboxRow key={`row${i.toString()}`}>
                    {arr.map((key) => (
                      <CheckboxCell key={key}>
                        <FormCheckbox
                          {...availableServicesChecks[key]}
                          hasError={form[key].hasError}
                          value={form[key].value}
                          handleChange={
                            key === 'serviceTransport'
                              ? handleModeOfTransport
                              : handleCheckbox
                          }
                        />
                      </CheckboxCell>
                    ))}
                  </CheckboxRow>
                ))}
              </CheckboxGroup>
              {Object.keys(servicesOther).map((key) => {
                if (
                  key === 'serviceModeOfTransport' &&
                  (!availableServicesChecks.serviceTransport ||
                    !form.serviceTransport ||
                    !form.serviceTransport.value)
                )
                  return false
                const { Component, value: initialValue, ...field } = fields[key]
                const { hasError, value } = form[key]
                return (
                  <Component
                    {...field}
                    hasError={hasError}
                    value={value}
                    handleChange={handleChange}
                    key={key}
                  />
                )
              })}
            </Section>
          </>
        )}
        <Spacing100 />
        <Section>
          <Spacing100 />
          <Headline>{primaryContactDetailsTitle}</Headline>
          <FormGrid>
            {primaryContactFields.map((half, i) => (
              <FormColumn key={`half${i.toString()}`}>
                {Object.keys(half).map((key, j) => {
                  const { Component, value: initialValue, ...field } = fields[
                    key
                  ]
                  const { hasError, value } = form[key]
                  return (
                    <Component
                      {...field}
                      hasError={hasError}
                      value={value}
                      handleChange={handleChange}
                      key={`${field.name}${j.toString()}`}
                    />
                  )
                })}
              </FormColumn>
            ))}
          </FormGrid>
        </Section>
        <AccSection>
          <Accordion
            name="groupBookSecContact"
            spacing={false}
            items={[
              {
                heading: secondaryContactDetailsTitle,
                callback: handleSecondaryToggle,
              },
            ]}
            villageSlug={villageSlug}>
            <FormGrid>
              {secondaryContactFields.map((half, i) => (
                <FormColumn key={`half${i.toString()}`}>
                  {Object.keys(half).map((key, j) => {
                    const { Component, value: initialValue, ...field } = fields[
                      key
                    ]
                    const { hasError, value } = form[key]
                    return (
                      <Component
                        {...field}
                        hasError={hasError}
                        value={value}
                        handleChange={handleChange}
                        key={`${field.name}${j.toString()}`}
                      />
                    )
                  })}
                </FormColumn>
              ))}
            </FormGrid>
          </Accordion>
        </AccSection>
        <Consent>
          <Spacing100 />
          <FormGrid center>
            {Object.keys(consentFields).map((key) => {
              const field = consentFields[key]
              const { hasError, value } = form[key]
              return (
                <FormColumn key={key}>
                  <FormCheckbox
                    {...field}
                    hasError={hasError}
                    value={value}
                    handleChange={handleCheckbox}
                  />
                </FormColumn>
              )
            })}
          </FormGrid>
        </Consent>
        <Actions>
          <form
            ref={submitFormRef}
            action="https://webto.salesforce.com/servlet/servlet.WebToLead"
            method="POST">
            {Object.keys(submitFields).map((key) => (
              <input
                type="hidden"
                name={key}
                value={submitFields[key]}
                key={key}
                id={key}
              />
            ))}
            <Recaptcha
              ref={recaptchaRef}
              sitekey={recaptchaKey}
              hl={locale}
              size="invisible"
              verifyCallback={verifyRecaptcha}
            />
            <SubmitBtn type="button" level="secondary" onClick={handleSubmit}>
              {ctaLabel}
            </SubmitBtn>
          </form>
        </Actions>
      </Center>
      <Spacing100 />
    </Wrapper>
  )
}

GroupBooking.propTypes = {
  accountIdLabel: PropTypes.string,
  additionalServicesTitle: PropTypes.string,
  address1Label: PropTypes.string,
  address2Label: PropTypes.string,
  address3Label: PropTypes.string,
  allVillagesInfo: PropTypes.arrayOf(
    PropTypes.shape({
      village: PropTypes.string,
      openingHours: PropTypes.shape({}),
      modeOfTransportOptions: PropTypes.arrayOf(PropTypes.string),
      serviceOptions: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  consentValidationMessage: PropTypes.string,
  countryLabel: PropTypes.string,
  countryOptions: PropTypes.arrayOf(PropTypes.string),
  ctaLabel: PropTypes.string,
  emailAddressLabel: PropTypes.string,
  emailValidationMessage: PropTypes.string,
  eventNameLabel: PropTypes.string,
  expectedArrivalHelpLabel: PropTypes.string,
  expectedArrivalLabel: PropTypes.string,
  firstNameLabel: PropTypes.string,
  groupsAndEventsTitle: PropTypes.string,
  groupTypeHelpLabel: PropTypes.string,
  groupTypeLabel: PropTypes.string,
  groupTypeOptions: PropTypes.arrayOf(PropTypes.string),
  jobTitleLabel: PropTypes.string,
  lastNameLabel: PropTypes.string,
  locale: PropTypes.string,
  locOrigin: PropTypes.string,
  modeOfTransportLabel: PropTypes.string,
  newsletterLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
  ]),
  notesLabel: PropTypes.string,
  numberOfVisitorsLabel: PropTypes.string,
  numberValidationMessage: PropTypes.string,
  organisationOrAgencyNameLabel: PropTypes.string,
  phoneNumberLabel: PropTypes.string,
  primaryContactDetailsTitle: PropTypes.string,
  privacyPolicyLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
  ]),
  recaptchaKey: PropTypes.string,
  regionLabel: PropTypes.string,
  regionOptions: PropTypes.arrayOf(PropTypes.string),
  requiredValidationMessage: PropTypes.string,
  secondaryContactDetailsTitle: PropTypes.string,
  selectVillageLabel: PropTypes.string,
  selectVillageOptions: PropTypes.arrayOf(PropTypes.string),
  serviceSelectionLabel: PropTypes.string,
  stateLabel: PropTypes.string,
  successUrl: PropTypes.string,
  timeOfArrivalLabel: PropTypes.string,
  timeOfDepartureLabel: PropTypes.string,
  titleLabel: PropTypes.string,
  titleOptions: PropTypes.arrayOf(PropTypes.string),
  townLabel: PropTypes.string,
  url: PropTypes.string.isRequired,
  visitorsCountryOfResidenceLabel: PropTypes.string,
  visitorsCountryOfResidenceOptions: PropTypes.arrayOf(PropTypes.string),
  zipCodeLabel: PropTypes.string,
  villageSlug: PropTypes.string,
}

GroupBooking.defaultProps = {
  accountIdLabel: '',
  additionalServicesTitle: '',
  address1Label: '',
  address2Label: '',
  address3Label: '',
  allVillagesInfo: [],
  consentValidationMessage: '',
  countryLabel: '',
  countryOptions: [],
  ctaLabel: '',
  emailAddressLabel: '',
  emailValidationMessage: '',
  eventNameLabel: '',
  expectedArrivalHelpLabel: '',
  expectedArrivalLabel: '',
  firstNameLabel: '',
  groupsAndEventsTitle: '',
  groupTypeHelpLabel: '',
  groupTypeLabel: '',
  groupTypeOptions: [],
  jobTitleLabel: '',
  lastNameLabel: '',
  locale: 'en',
  locOrigin: '',
  modeOfTransportLabel: '',
  newsletterLabel: '',
  notesLabel: '',
  numberOfVisitorsLabel: '',
  numberValidationMessage: '',
  organisationOrAgencyNameLabel: '',
  phoneNumberLabel: '',
  primaryContactDetailsTitle: '',
  privacyPolicyLabel: '',
  recaptchaKey: '',
  regionLabel: '',
  regionOptions: [],
  requiredValidationMessage: '',
  secondaryContactDetailsTitle: '',
  selectVillageLabel: '',
  selectVillageOptions: [],
  serviceSelectionLabel: '',
  stateLabel: '',
  successUrl: '',
  timeOfArrivalLabel: '',
  timeOfDepartureLabel: '',
  titleLabel: '',
  titleOptions: [],
  townLabel: '',
  visitorsCountryOfResidenceLabel: '',
  visitorsCountryOfResidenceOptions: [],
  zipCodeLabel: '',
  villageSlug: '',
}

export default GroupBooking
