import styled, { keyframes } from 'styled-components'
import theme from 'styles/theme'
import { Title60 } from 'components/Typography'
import easing from 'styles/easing'

export const Wrapper = styled.div`
  color: ${theme.colors.sagedark};
  margin: 0;

  &::before {
    border-top: 1px solid ${theme.colors.sagelight};
    content: '';
    display: block;
    margin: 0 auto;
    width: calc(100% - 80px);

    ${theme.mediaQueries.large} {
      max-width: 780px;
      width: 66%;
    }
  }
`

export const Heading = styled(Title60)`
  text-align: center;
`

export const List = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  border-top: 1px solid ${theme.colors.alabaster};
  display: block;
  margin: 0;
  padding: 0;
`

export const ItemHeading = styled.button`
  appearance: none;
  background: none;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  font: inherit;
  outline: none;
  padding: 24px 20px;
  text-align: inherit;
  width: 100%;

  &::after {
    border: 1px solid;
    border-width: 0 1px 1px 0;
    content: '';
    display: block;
    height: 9px;
    transform: translateY(12px) rotate(45deg);
    width: 9px;
  }

  span {
    flex: 1 1 100%;
    margin-right: 15px;
  }

  &[aria-expanded='true'] {
    &::after {
      transform: translateY(16px) rotate(-135deg);
    }
  }

  ${theme.mediaQueries.large} {
    padding: 22px 20px;
  }
`

const CopyOpen = keyframes`
  0% { height: 0 }
`

const CopyClose = keyframes`
  100% { height: 0 }
`

export const Copy = styled.div`
  animation: ${CopyOpen} 0.3s ${easing.easeOutQuart};
  overflow: hidden;
  transition: visibility 0.3s;

  &[aria-hidden='true'] {
    animation: ${CopyClose} 0.3s ${easing.easeOutQuart} forwards;
    height: 0;
    visibility: hidden;
  }
`

export const CopyInner = styled.div`
  padding: 0 20px 14px;
`
