export default function getHourOptions(min = '09:00', max = '22:00') {
  function getDateNumber(time) {
    const [hours, minutes] = time.split(':')
    return new Date().setHours(parseInt(hours, 10), parseInt(minutes, 10))
  }

  function doubleDigit(n) {
    return `0${n}`.slice(-2)
  }

  let dateStart = getDateNumber(min)
  const dateEnd = getDateNumber(max)
  const hours = []

  while (dateStart <= dateEnd) {
    const dateObj = new Date(dateStart)
    const HH = dateObj.getHours()
    const MM = dateObj.getMinutes()
    hours.push(`${doubleDigit(HH)}:${doubleDigit(MM)}`)
    dateStart += 9e5 // Add 15 minutes
  }

  return hours
}
