export default function getOpening(allVillages) {
  const allOpening = {
    null: {
      daysOfWeek: new Array(7).fill(false),
      hours: new Array(7).fill({ opening: '00:00', closing: '00:00' }),
      startDate: new Date(),
      exceptions: [],
    },
  }
  allVillages.forEach((allVillage) => {
    const { village, openingHours } = allVillage
    const dayKeys = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]
    const hours = Array.from(dayKeys, (key) => ({
      closing: openingHours[`${key}ClosingTime`],
      opening: openingHours[`${key}OpeningTime`],
    }))
    const daysOfWeek = Array.from(
      dayKeys,
      (key) => !openingHours[`${key}ClosedFlag`]
    )
    const datePlusOne = (day) => new Date(day.setDate(day.getDate() + 1))
    const findStart = (day) => {
      return daysOfWeek[day.getDay()] ? day : findStart(datePlusOne(day))
    }
    const startDate = findStart(datePlusOne(new Date()))

    allOpening[village] = {
      daysOfWeek,
      hours,
      startDate,
      exceptions: openingHours.exceptions || [],
    }
  })
  return allOpening
}
