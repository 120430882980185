import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import GroupBooking from 'components/GroupBooking'
import Hero from 'components/Hero'
import Layout from 'components/Layout'
import SectionHeader from 'components/SectionHeader'
import { Spacing100 } from 'styles/sharedStyle'
import { formVillageOptions, formatValue } from 'utils/formVillageOptions'

import { constructUrlPath } from 'utils/urlUtils'

const GroupBookingPageT10 = (props) => {
  const {
    pageContext: {
      recaptchaKey,
      nodeLocale,
      villageSlug,
      pageLevel,
      translatedSlugs,
      translatedServices,
    },
    data: {
      allVillages: { edges: allVillages },
      page: { formMetaData, hero, locale, jsonLdSchema, village },
    },
    location: { origin: locOrigin },
  } = props

  const formLabels = Object.assign(
    {
      selectVillageOptions: formVillageOptions(allVillages),
    },
    formMetaData &&
      formMetaData.find(
        (metaData) =>
          metaData.__typename === 'ContentfulLabelGroupBookingsLabelsLab11'
      )
  )

  const sectionHeader =
    formMetaData &&
    formMetaData.find(
      (metaData) => metaData.__typename === 'ContentfulCompSectionHeaderSch01'
    )

  const sectionHeaderProps = sectionHeader
    ? {
        eyebrow: sectionHeader.eyebrow ? sectionHeader.eyebrow : null,
        title: sectionHeader.headline ? sectionHeader.headline : null,
        content: sectionHeader.bodyCopy ? sectionHeader.bodyCopy : null,
        isPageHeader: true,
        withMaxWidth: true,
        background: 'Grey',
      }
    : null

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const url = formMetaData
    ? constructUrlPath({
        nodeLocale,
        pageLevel,
        translatedServices,
        translatedSlugs,
        url: formMetaData[0].successUrl,
        villageSlug,
      })
    : ''

  const allVillagesInfo = allVillages.map((item) => {
    const {
      node: { name, openingHours, modeOfTransportOptions, serviceOptions },
    } = item
    return {
      village: formatValue(name),
      openingHours,
      modeOfTransportOptions,
      serviceSelectionOptions: serviceOptions,
    }
  })

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>
      {heroProps && <Hero {...heroProps} />}
      {sectionHeader && (
        <>
          <Spacing100 />
          <SectionHeader {...sectionHeaderProps} />
        </>
      )}
      <Spacing100 />
      {formMetaData && (
        <GroupBooking
          {...{
            recaptchaKey,
            locale,
            url,
            allVillagesInfo,
            ...formLabels,
          }}
          locOrigin={locOrigin}
          villageSlug={
            pageLevel === 'collection'
              ? 'The Bicester Collection'
              : village?.name
          }
        />
      )}
    </Layout>
  )
}

GroupBookingPageT10.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    origin: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    recaptchaKey: PropTypes.string,
    nodeLocale: PropTypes.string,
    pageLevel: PropTypes.string,
    translatedServices: PropTypes.instanceOf(Object),
    translatedSlugs: PropTypes.instanceOf(Object),
    villageSlug: PropTypes.string,
  }).isRequired,
}

export const GroupBookingQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $pageTypeSlug: String
    $villageSlug: String
  ) {
    page: contentfulPageTemplateFormsT10(id: { eq: $id }) {
      id
      jsonLdSchema {
        jsonLdSchema
      }
      hero {
        eyebrow
        headline
        ...heroMediaQuery
      }
      locale: node_locale
      formMetaData {
        __typename
        ... on Node {
          ... on ContentfulLabelGroupBookingsLabelsLab11 {
            groupsAndEventsTitle
            selectVillageLabel
            groupTypeLabel
            groupTypeOptions
            groupTypeHelpLabel
            eventNameLabel
            expectedArrivalLabel
            expectedArrivalHelpLabel
            timeOfArrivalLabel
            timeOfDepartureLabel
            numberOfVisitorsLabel
            visitorsCountryOfResidenceLabel
            visitorsCountryOfResidenceOptions
            regionLabel
            regionOptions
            additionalServicesTitle
            serviceSelectionLabel
            modeOfTransportLabel
            notesLabel
            primaryContactDetailsTitle
            secondaryContactDetailsTitle
            organisationOrAgencyNameLabel
            accountIdLabel
            titleLabel
            titleOptions
            firstNameLabel
            lastNameLabel
            jobTitleLabel
            phoneNumberLabel
            emailAddressLabel
            address1Label
            address2Label
            address3Label
            townLabel
            stateLabel
            zipCodeLabel
            countryLabel
            countryOptions
            privacyPolicyLabel {
              childMarkdownRemark {
                html
              }
            }
            newsletterLabel {
              childMarkdownRemark {
                html
              }
            }
            ctaLabel
            requiredValidationMessage
            emailValidationMessage
            numberValidationMessage
            consentValidationMessage
            successUrl
          }
          ... on ContentfulCompSectionHeaderSch01 {
            headline
            eyebrow
            bodyCopy {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      village {
        name
        code
        currency
        emailRecipient
        villageSlug: slug
        servicesHeader {
          ...header
        }
        serviceOptions
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        openingHours {
          ...villageOpeningHours
        }
        defaultLocale
        openingStatusLabel
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    allVillages: allContentfulEntityVillageEnt01(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          modeOfTransportOptions
          name
          openingHours {
            ...villageOpeningHours
          }
          serviceOptions
        }
      }
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
  }
`

export default GroupBookingPageT10
