import styled from 'styled-components'
import { Body16, Title60 } from 'components/Typography'
import Button from 'components/Button'
import theme from 'styles/theme'

export const Wrapper = styled.div`
  color: ${theme.colors.sagedark};
  padding: 0 20px;
`

export const Center = styled.div`
  ${theme.mediaQueries.medium} {
    margin: 0 auto;
    max-width: 780px;
  }
`

export const Section = styled.section`
  border-top: 1px solid ${theme.colors.sagelight};
`

export const Headline = styled(Title60)`
  margin: 0 0 30px;
  text-align: center;
`

export const FormGrid = styled.div`
  ${theme.mediaQueries.medium} {
    display: flex;
    justify-content: space-between;
    align-items: ${(props) => (props.center ? 'center' : 'normal')};
  }

  ${Headline} + & {
    margin-top: 30px;

    ${theme.mediaQueries.large} {
      margin-top: 50px;
    }
  }
`

export const FormColumn = styled.div`
  ${theme.mediaQueries.medium} {
    flex: none;
    width: calc(50% - 10px);

    > div:last-child {
      margin-bottom: 0;
    }
  }

  ${({ index, section }) =>
    section === 'groupFields' &&
    index === 1 &&
    `
    > div:nth-child(-n+2) {
      margin-top: 0;
      width: calc(50% - 10px);

      ${theme.mediaQueries.medium} {
        margin-top: 0.9375rem;
      }
    }

    > div:first-child {
      float: left;
    }

    > div:nth-child(2) {
      float: right;
    }

    > div:nth-child(3) {
      clear: both;
    }
  `}
`

export const CheckboxGroupLabel = styled(Body16)`
  color: ${theme.colors.sagemedium};
  margin: -10px auto 0;
  max-width: 550px;
  text-align: center;
`

export const CheckboxGroup = styled.div`
  margin-top: 30px;

  ${theme.mediaQueries.medium} {
    display: table;
    margin: 35px 0 50px;
    width: 100%;
  }
`

export const CheckboxRow = styled.div`
  ${theme.mediaQueries.medium} {
    display: flex;
    flex-wrap: wrap;
  }
`

export const CheckboxCell = styled.div`
  margin-top: 20px;

  ${theme.mediaQueries.medium} {
    flex: 1 0 33.333333%;
    flex-grow: inherit;
    margin-top: 0;
    padding-top: 15px;
    white-space: nowrap;
    width: 40%;
  }

  .form-check__label-text {
    align-items: center;
    display: flex;
    margin: 0;
  }
`

export const AccSection = styled.section`
  margin: 30px -20px 0;

  ${theme.mediaQueries.medium} {
    margin: 50px 0 0;
  }

  > div {
    margin: 0;

    &::before {
      content: none;
      display: none;
    }
  }

  div[id*='groupBookSecContact'] > div {
    ${theme.mediaQueries.smallOnly} {
      padding: 0 20px;
    }
  }
`

export const Consent = styled.div`
  .form-check__label-text {
    margin: 15px 0 15px;

    ${theme.mediaQueries.large} {
      margin: 7px 0 7px;
    }

    p {
      margin: 0;
    }

    p + p {
      margin-top: 10px;
    }
  }

  a {
    color: ${theme.colors.sagemedium};
    text-decoration: underline;
  }
`

export const Actions = styled.div`
  margin-top: 50px;

  .grecaptcha-badge {
    z-index: 1;
  }
`

export const SubmitBtn = styled(Button)`
  cursor: pointer;
  display: block;
  height: 60px;
  margin: 0 auto;
  max-width: 450px;
  width: 100%;

  ${theme.mediaQueries.medium} {
    margin: 0 auto;
    max-width: none;
    width: calc(50% - 10px);
  }
`
