import React from 'react'
import PropTypes from 'prop-types'
import MarkdownRenderer from 'components/MarkdownRenderer'
import { Title26 } from 'components/Typography'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'
import { Copy, CopyInner, ItemHeading, ListItem } from './Accordion.style'

class AccordionItem extends React.Component {
  constructor(props) {
    super(props)

    this.id = `${props.name}_${props.index}`

    this.copyEl = React.createRef()

    this.headingClick = () => {
      const { active, index, toggle, heading, villageSlug } = this.props
      toggle(index)
      trackGTM(
        'accordion interaction',
        'accordion interaction',
        'gift card',
        heading
      )
      GA4(active ? 'accordion_close' : 'accordion_click', {
        headline: heading,
        village_name: villageSlug,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { active, callback } = this.props
    if (prevProps.active !== active) {
      const copy = this.copyEl.current
      clearTimeout(this.updateTimeout)
      copy.style.height = `${copy.scrollHeight}px`
      this.updateTimeout = setTimeout(() => {
        copy.style.height = null
      }, 300)
    }
    if (callback) {
      callback(active)
    }
  }

  render() {
    const { active, children, copy, heading } = this.props
    return (
      <ListItem>
        <ItemHeading
          onClick={this.headingClick}
          aria-controls={this.id}
          aria-expanded={active}>
          <Title26 as="span">{heading}</Title26>
        </ItemHeading>
        <Copy ref={this.copyEl} id={this.id} aria-hidden={!active}>
          {children || <CopyInner as={MarkdownRenderer} html={copy} />}
        </Copy>
      </ListItem>
    )
  }
}

AccordionItem.propTypes = {
  active: PropTypes.bool,
  callback: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  copy: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  toggle: PropTypes.func,
  villageSlug: PropTypes.string,
}

AccordionItem.defaultProps = {
  active: false,
  callback: null,
  children: null,
  copy: '',
  heading: '',
  index: 0,
  name: 'accordion',
  toggle: () => {},
  villageSlug: '',
}

export default AccordionItem
