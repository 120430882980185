import { validations } from 'utils/form-validation'
import formOptionFormat from 'utils/formOptionFormat'
import { FormDatepicker, FormDropdown, FormText } from 'components/Forms'
import getHourOptions from './getHourOptions'

export default function getFields({
  accountIdLabel,
  address1Label,
  address2Label,
  address3Label,
  consentValidationMessage,
  countryLabel,
  countryOptions,
  emailAddressLabel,
  emailValidationMessage,
  eventNameLabel,
  expectedArrivalHelpLabel,
  expectedArrivalLabel,
  firstNameLabel,
  groupTypeHelpLabel,
  groupTypeLabel,
  groupTypeOptions,
  jobTitleLabel,
  lastNameLabel,
  locale,
  modeOfTransportLabel,
  modeOfTransportOptions,
  newsletterLabel,
  notesLabel,
  numberOfVisitorsLabel,
  organisationOrAgencyNameLabel,
  phoneNumberLabel,
  privacyPolicyLabel,
  regionLabel,
  regionOptions,
  requiredValidationMessage,
  selectVillageLabel,
  selectVillageOptions,
  serviceSelectionOptions,
  startDate,
  stateLabel,
  timeOfArrivalLabel,
  timeOfDepartureLabel,
  titleLabel,
  titleOptions,
  townLabel,
  visitorsCountryOfResidenceLabel,
  zipCodeLabel,
}) {
  const {
    notNull,
    stringNotNullOrEmpty,
    validateEmail,
    validateEmailNotRequired,
  } = validations()

  const htmlElseString = (entry) => {
    return entry.childMarkdownRemark ? entry.childMarkdownRemark.html : entry
  }

  const requiredField = {
    value: '',
    errorMessage: requiredValidationMessage,
    validation: stringNotNullOrEmpty,
  }

  const reqTextField = {
    Component: FormText,
    ...requiredField,
  }

  const reqEmail = {
    value: '',
    errorMessage: emailValidationMessage,
    validation: validateEmail,
    Component: FormText,
  }

  const notReqEmail = {
    value: '',
    errorMessage: emailValidationMessage,
    validation: validateEmailNotRequired,
    Component: FormText,
  }

  const reqDropdown = {
    Component: FormDropdown,
    ...requiredField,
  }

  const groupFields = [
    {
      village: {
        name: 'village',
        queryName: '00Nb0000007ppFC',
        label: `${selectVillageLabel} *`,
        options: formOptionFormat(selectVillageOptions),
        ...reqDropdown,
        value: selectVillageOptions ? selectVillageOptions[0].value : '',
      },
      groupType: {
        name: 'groupType',
        queryName: '00Nb0000007pj2B',
        label: `${groupTypeLabel} *`,
        helpLabel: groupTypeHelpLabel,
        options: formOptionFormat(groupTypeOptions),
        ...reqDropdown,
      },
      eventName: {
        name: 'eventName',
        queryName: '00Nb0000007pj0B',
        label: eventNameLabel,
        value: '',
        Component: FormText,
      },
      expectedArrival: {
        name: 'expectedArrival',
        queryName: '00Nb0000007pmDB',
        label: `${expectedArrivalLabel} *`,
        helpLabel: expectedArrivalHelpLabel,
        startDate,
        value: startDate,
        locale,
        Component: FormDatepicker,
      },
    },
    {
      timeOfArrival: {
        name: 'timeOfArrival',
        queryName: 'timeOfArrival',
        label: `${timeOfArrivalLabel} *`,
        options: formOptionFormat(getHourOptions()),
        ...reqDropdown,
      },
      timeOfDeparture: {
        name: 'timeOfDeparture',
        queryName: '00Nb0000007pmDL',
        label: `${timeOfDepartureLabel} *`,
        options: formOptionFormat(getHourOptions()),
        ...reqDropdown,
      },
      numberOfVisitors: {
        name: 'numberOfVisitors',
        queryName: '00Nb0000007ps7O',
        label: `${numberOfVisitorsLabel} *`,
        ...reqTextField,
      },
      visitorsCountryOfResidence: {
        name: 'visitorsCountryOfResidence',
        queryName: '00Nb0000007pmOa',
        label: `${visitorsCountryOfResidenceLabel} *`,
        options: formOptionFormat(countryOptions),
        ...reqDropdown,
      },
      region: {
        name: 'region',
        queryName: '00Nb0000007ppLN',
        label: `${regionLabel} *`,
        options: formOptionFormat(regionOptions),
        ...reqDropdown,
      },
    },
  ]

  const serviceCheckNames = {
    '00Nb0000007xQxD': 'serviceBoutiqueViewing',
    '00Nb0000007xQxF': 'serviceWelcomePack',
    '00Nb0000007ppLw': 'serviceGift',
    '00Nb0000007ppLr': 'serviceGiftCard',
    '00Nb0000007xQxE': 'serviceVIP',
    '00Nb0000007ppLm': 'serviceFoodBev',
    '00Nb0000007ppLX': 'serviceTransport',
  }

  const servicesChecks = {}
  Object.keys(serviceCheckNames).forEach((key) => {
    const name = serviceCheckNames[key]
    const queryName = key
    const copy = ''
    servicesChecks[serviceCheckNames[key]] = {
      name,
      queryName,
      copy,
    }
  })

  if (serviceSelectionOptions) {
    Array.prototype.forEach.call(serviceSelectionOptions, (str) => {
      const [copy, queryName] = str.split(/\s*\|\s*/)
      const name = serviceCheckNames[queryName]

      if (queryName && queryName in serviceCheckNames) {
        servicesChecks[name] = {
          name,
          queryName,
          copy,
        }
      }
    })
  }

  const servicesOther = {
    serviceModeOfTransport: {
      name: 'serviceModeOfTransport',
      queryName: '00Nb0000007ppLh',
      label: modeOfTransportLabel,
      options: formOptionFormat(modeOfTransportOptions),
      value: '',
      Component: FormDropdown,
    },
    serviceNotes: {
      name: 'serviceNotes',
      queryName: '00Nb0000007ppM1',
      label: notesLabel,
      value: '',
      Component: FormText,
    },
  }

  const primaryContactFields = [
    {
      priAgency: {
        name: 'priAgency',
        queryName: 'company',
        label: `${organisationOrAgencyNameLabel} *`,
        ...reqTextField,
      },
      priAccount: {
        name: 'priAccount',
        queryName: '00Nb000000A1KKn',
        label: accountIdLabel,
        value: '',
        Component: FormText,
      },
      priTitle: {
        name: 'priTitle',
        queryName: 'salutation',
        label: titleLabel,
        options: formOptionFormat(titleOptions),
        value: '',
        Component: FormDropdown,
      },
      priFirstName: {
        name: 'priFirstName',
        queryName: 'first_name',
        label: `${firstNameLabel} *`,
        ...reqTextField,
      },
      priLastName: {
        name: 'priLastName',
        queryName: 'last_name',
        label: `${lastNameLabel} *`,
        ...reqTextField,
      },
      priJob: {
        name: 'priJob',
        queryName: 'title',
        label: `${jobTitleLabel} *`,
        ...reqTextField,
      },
      priPhone: {
        name: 'priPhone',
        queryName: 'phone',
        label: `${phoneNumberLabel} *`,
        ...reqTextField,
      },
    },
    {
      priEmail: {
        name: 'priEmail',
        queryName: 'email',
        label: `${emailAddressLabel} *`,
        ...reqEmail,
      },
      priAddress1: {
        name: 'priAddress1',
        queryName: 'street',
        label: address1Label,
        value: '',
        Component: FormText,
      },
      priCity: {
        name: 'priCity',
        queryName: 'city',
        label: townLabel,
        value: '',
        Component: FormText,
      },
      priState: {
        name: 'priState',
        queryName: 'state',
        label: stateLabel,
        value: '',
        Component: FormText,
      },
      priZip: {
        name: 'priZip',
        queryName: 'zip',
        label: zipCodeLabel,
        value: '',
        Component: FormText,
      },
      priCountry: {
        name: 'priCountry',
        queryName: 'country',
        label: `${countryLabel} *`,
        options: formOptionFormat(countryOptions),
        ...reqDropdown,
      },
    },
  ]

  const secondaryContactFields = [
    {
      secAgency: {
        name: 'secAgency',
        queryName: '00Nb0000007ppR2',
        label: `${organisationOrAgencyNameLabel}`,
        value: '',
        Component: FormText,
      },
      secTitle: {
        name: 'secTitle',
        queryName: '00Nb0000007ppbb',
        label: titleLabel,
        options: formOptionFormat(titleOptions),
        value: '',
        Component: FormDropdown,
      },
      secFirstName: {
        name: 'secFirstName',
        queryName: '00Nb0000007ppb2',
        label: `${firstNameLabel}`,
        value: '',
        Component: FormText,
      },
      secLastName: {
        name: 'secLastName',
        queryName: '00Nb0000007ppb7',
        label: `${lastNameLabel}`,
        value: '',
        Component: FormText,
      },
      secJob: {
        name: 'secJob',
        queryName: '00Nb0000007ppbH',
        label: `${jobTitleLabel}`,
        value: '',
        Component: FormText,
      },
      secPhone: {
        name: 'secPhone',
        queryName: '00Nb0000007ppbC',
        label: `${phoneNumberLabel}`,
        value: '',
        Component: FormText,
      },
      secEmail: {
        name: 'secEmail',
        queryName: '00Nb0000007ppas',
        label: `${emailAddressLabel}`,
        ...notReqEmail,
      },
    },
    {
      secAddress1: {
        name: 'secAddress1',
        queryName: '00Nb0000007ppRV',
        label: address1Label,
        value: '',
        Component: FormText,
      },
      secAddress2: {
        name: 'secAddress2',
        queryName: '00Nb0000007ppRW',
        label: address2Label,
        value: '',
        Component: FormText,
      },
      secAddress3: {
        name: 'secAddress3',
        queryName: '00Nb0000007ppai',
        label: address3Label,
        value: '',
        Component: FormText,
      },
      secCity: {
        name: 'secCity',
        queryName: '00Nb0000007pqDl',
        label: townLabel,
        value: '',
        Component: FormText,
      },
      secState: {
        name: 'secState',
        queryName: '00Nb0000007ppbR',
        label: stateLabel,
        value: '',
        Component: FormText,
      },
      secZip: {
        name: 'secZip',
        queryName: '00Nb0000007pqHy',
        label: zipCodeLabel,
        value: '',
        Component: FormText,
      },
      secCountry: {
        name: 'secCountry',
        queryName: '00Nb0000007ppan',
        label: `${countryLabel}`,
        options: formOptionFormat(countryOptions),
        value: '',
        Component: FormDropdown,
      },
    },
  ]

  const consentFields = {
    consentTerms: {
      name: 'consentTerms',
      queryName: '00Nb0000007pqg5',
      copy: htmlElseString(privacyPolicyLabel),
      validation: notNull,
      errorMessage: consentValidationMessage,
    },
    consentNewsletter: {
      name: 'consentNewsletter',
      queryName: 'NewsletterCheck',
      copy: htmlElseString(newsletterLabel),
    },
  }

  const fields = Object.assign(
    {},
    groupFields[0],
    groupFields[1],
    servicesChecks,
    servicesOther,
    primaryContactFields[0],
    primaryContactFields[1],
    secondaryContactFields[0],
    secondaryContactFields[1],
    consentFields
  )

  return {
    consentFields,
    fields,
    groupFields,
    primaryContactFields,
    secondaryContactFields,
    servicesChecks,
    servicesOther,
  }
}
