export default function getServices(allVillages) {
  const allServices = {}
  allVillages.forEach((allVillage) => {
    const {
      village,
      modeOfTransportOptions,
      serviceSelectionOptions,
    } = allVillage
    allServices[village] = {
      modeOfTransportOptions,
      serviceSelectionOptions,
    }
  })
  return allServices
}
